// Packages
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useNavigate} from "react-router-dom";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";

// Styles
import { useStyles } from "./styles";

export default function AllProductsCard(props) {
    const classes = useStyles();
    const navigate = useNavigate();

    function loadProduct(product){
        props.setProductData(product);
        navigate("/product");
    }
    
  
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.grid}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={`AAIS Products`}
            />
            <CardContent className={classes.cardContent}>
              <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.text}>
                  <Link href={`/#/browse`} className={classes.link} id={`search-link`} name={`search-link`}>{`Search All AAIS Products`}</Link>
                  <div></div>
                  <Link className={classes.link} id={`allProducts-link-auto`} name={`allProducts-link-auto`} onClick={() => loadProduct('BT')}>{`Browse Auto Lines`}</Link>
                  <div></div>
                  <Link className={classes.link} id={`allProducts-link-commercial`} name={`allProducts-link-commercial`} onClick={() => loadProduct('AP')}>{`Browse Commercial Lines`}</Link>
                  <div></div>
                  <Link className={classes.link} id={`allProducts-link-farmag`} name={`allProducts-link-farmag`} onClick={() => loadProduct('AGXL')}>{`Browse Farm & Ag Lines`}</Link>
                  <div></div>
                  <Link className={classes.link} id={`allProducts-link-inlandmarine`} name={`allProducts-link-inlandmarine`} onClick={() => loadProduct('IMG')}>{`Browse Inland Marine Lines`}</Link>
                  <div></div>
                  <Link className={classes.link} id={`allProducts-link-personal`} name={`allProducts-link-personal`} onClick={() => loadProduct('DP')}>{`Browse Personal Lines`}</Link>
                  <div></div>
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </React.Fragment>
    );
}