// Packages
import React, { useState, useContext, useEffect } from "react";

// MUI
import { Grid, CardHeader } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddLocationIcon from '@material-ui/icons/AddLocation';


// Context
import MeContext from "contexts/MeContext";

// Data
import * as OrgApi from "apis/OrgApi";
//import * as Affiliation from "apis/AffiliationApi";

import { useStyles } from "./styles";

export default function AffiliationRequestModal(props) {
  
  const classes = useStyles();
  const me = useContext(MeContext);
  const { firstName, lastName, email, phoneNumbers } = me;
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [isRequestValid, setIsRequestValid] = useState(false);
  const [myOrg, setMyOrg] = useState("");
  

  const isSubmitDisabled = !additionalInfo;


  useEffect(() => {
    async function fetchOrg() {
      if(me.orgs && me.orgs?.[0] != null){
        const thisOrg =  await OrgApi.get(me.orgs[0]);
        setMyOrg(thisOrg.name);
      }
    }
    fetchOrg();
  }, []);

  

  const getMainPhoneNumber = () => {
    if (!phoneNumbers) return "";

    let contactNumbers = [];
    const officeStr = 'office';
    if(officeStr in phoneNumbers && phoneNumbers[officeStr][0]){
      const number = phoneNumbers[officeStr][0];
      return ` ${number.countryCode}  ${number.phoneNumber} ${
        number.extension ? `, ext: ${number.extension} ` : " "
      }`;
    }

    for (const location in phoneNumbers) {
      contactNumbers = phoneNumbers[location].map((number) => {
        return ` ${number.countryCode}  ${number.phoneNumber} ${
          number.extension ? `, ext: ${number.extension} ` : " "
        }`;
      });
    }

    return contactNumbers[0];
  };

  const handleSubmit = async () => {
    const user = `${firstName} ${lastName}`;
    
    const number = getMainPhoneNumber();
    const submission = {
      email: me.email,
      firstName: user,
      phone: number,
      content: additionalInfo,
      productsToAffiliateObj: props.productsToAffiliateObj.map(obj => obj.name), //.filter((obj) => {return obj.name;}),
      org: myOrg,
    };
    //const response = await Affiliation.sendProductRequestAffiliation(submission);
    const response = await {'status': true};//TODO: implement APIs
    console.log('submission');
    console.log(submission);
    //const response = {'status': false};

    if (response.status === "error") {
      setIsRequestValid(false);
    } else {
      setIsRequestValid(true);
    }

    setIsRequestSubmitted(true);
  };

  const handleKeepBrowsing = () => {
    props.handleRequestModal();
  };



  

  return (
    <React.Fragment>
      {isRequestSubmitted ? (
        isRequestValid ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your request was submitted. An AAIS team member will
                contact you soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleKeepBrowsing}
                color="primary"
                data-test="keep-browsing-products"
              >
                Keep Browsing Products
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                There was a problem while submitting your data, please try again
                later or contact an administrator.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleKeepBrowsing}
                color="primary"
                data-test="keep-browsing-products"
              >
                Keep Browsing Products
              </Button>
            </DialogActions>
          </>
        )
      ) : (
        <React.Fragment>
          <CardHeader
            title="With what would you like to affiliate?"
            action={
              <IconButton
                aria-label="clear"
                data-test="clear"
                color="primary"
                onClick={props.handleRequestModal}
              >
                <ClearIcon />
              </IconButton>
            }
          />
          {}
          {props.productsToAffiliateObj && (props.productsToAffiliateObj.length > 0) && (props.productsToAffiliateObj.map((product) => {
            return (<li id={`affiliate-product-${product.keyName}-LI`} className={classes.dropdownItem} key={`affiliate-product-${product.keyName}-LI`} data-value={product.keyName}>
                <Grid className={classes.wrapper}>
                    <Grid item xs={12} className={classes.gridItem} >
                        <FormControlLabel control={<Checkbox defaultChecked color="primary" />} label={product.name} id={`affiliate-product-main-button-${product.name}`} name={`affiliate-product-main-button-${product.name}`}  className={classes.requestAffiliationLI} /><AddLocationIcon></AddLocationIcon>
                    </Grid>

                    {/*<Grid className={classes.gridSubContainer} container xs={12}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControlLabel control={<Checkbox checked={false} color="primary" />} label={"Forms"} id={`affiliate-product-forms-button-${product.name}`} name={`affiliate-product-forms-button-${product.name}`} /> <AddLocationIcon></AddLocationIcon>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControlLabel control={<Checkbox defaultChecked color="primary" />} label={"Rules"} id={`affiliate-product-rules-button-${product.name}`} name={`affiliate-product-rules-button-${product.name}`} /> <AddLocationIcon></AddLocationIcon>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControlLabel control={<Checkbox defaultChecked color="primary" />} label={"Loss"} id={`affiliate-product-loss-button-${product.name}`} name={`affiliate-product-loss-button-${product.name}`}  /> <AddLocationIcon></AddLocationIcon>
                        </Grid>
                    </Grid>*/}
                      
                </Grid>
                </li>);
            }))}
          <DialogContent>
              <Grid item xs={12}>

                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                  autoCorrect="off"
                  spellCheck="false"
                  placeholder="Please provide more information as desired."
                  data-test="request-modal-additional-info"
                  value={additionalInfo}
                  onChange={(event) => setAdditionalInfo(event.target.value)}
                ></TextField>
              </Grid>
          </DialogContent>
          <DialogActions>
          <Button
              variant="contained"
              onClick={props.handleRequestModal}
              color="primary"
              data-test="cancel-modal-request"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              data-test="submit-modal-request"
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
