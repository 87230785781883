// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    width: "500px"
  },
  dialogContent: {
    width: "500px"
  },
  grid: {
    marginTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  requestAffiliationLI: {
    paddingLeft: 25,
    width: "100%"
  },
  wrapper: {
    display: "grid",
    //orientation: "horizontal",
    gridTemplateColumns: "50px 50px 50px",
  },
  gridItem: {
    display: "grid",
    paddingBottom: "20px",

    gridTemplateColumns: "max-content 5px 10px",

  },
  gridSubContainer:{
    display: "grid",
    transform: "translateY(50%)",
    //orientation: "horizontal",
    gridTemplateColumns: "110px 110px 110px 110px",
  },
  dropdownItem:{
    listStyleType: "none"
  }
  
  
}));
