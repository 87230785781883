// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  summaryBar: {
    //display: "flex",
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    paddingTop: "15px",
    fontSize: "22px",
    color: "white",
    alignItems: "center"
  },
  mainBannerDiv: {
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  mainBanner: {
    display: "inline-block",//flex,
    //flexDirection: "row",
    flexFlow: "wrap",
    paddingLeft: "15px",
    paddingTop: "1px",
    fontSize: "22px",
    //border: "1px",
    width: "100%",
    borderColor: "black",
    color: "black",
    height: "80px",
    lineHeight: 1.8,
    backgroundColor: "white",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  mainSubBanner: {
    fontSize: "14px",
    width: "min-content",
    backgroundColor: "white",
  },
  mainSubBannerNA: {
    fontSize: "14px",
    color: "red",
    width: "250px",
    backgroundColor: "white",
  },
  lowerBanner: {
    paddingLeft: "15px",
    paddingRight: "10px",
    color: "white",
    height: "52px",
    backgroundColor: "white",
    borderTopWidth: "0px",
    //borderBottomWidth: "1px",
    //borderBottomColor: "black",
    //borderBottomStyle: "solid",
    boxSizing: "border-box",
    borderBottomLeftRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    elevation1: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    inline:16

  },
  formBannerHeader: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "18px",
    color: "black",
    height: "20px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  formBanner: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "14px",
    color: "black",
    height: "20px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  formBannerNA: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "14px",
    color: "red",
    height: "20px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  formBannerMissing: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "19px",
    fontSize: "14px",
    height: "20px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  requestAffiliationButton: {
    backgroundColor: "#1f4287",
    color: "white",
    width: "170px",
    height: "32px",
    float: "right",
    marginRight: "10px",
    marginTop:"10px",
  },
  summaryDesc: {
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "15px",
    fontSize: "14px",
    color: "white",
    alignItems: "center"
  },
  productDescription: {
    background: "#f5f5f5",//theme.palette.background.secondary,
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "2px",
    marginBottom: "-20px",
    height: "fit-content",//"60px",
    fontSize: "14px",
    borderBottomWidth: "0px",
    color: "black",
  },
  buttonBar: {
    display: "flex",
    marginTop: "20px"
  },
  cardGrid: {
    display: "flex",
    marginTop: "30px",
    minWidth: "460px",
    height: "500px"
  },
  cardGridM: {
    display: "flex",
    marginTop: "20px",
    minWidth: "450px",
    height: "395px"
  },
  cardGridL: {
    display: "flex",
    marginTop: "20px",
    minWidth: "450px",
    height: "395px"
  },
  bigCardGrid: {
    display: "flex",
    marginTop: "20px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    //height: "500px",
  },
  mainCardGrid: {
    //marginTop: "30px",
    //height: "465px",
    overflow: "scroll",
    width: "auto",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    //maxWidth: "60%"
  },
}));
