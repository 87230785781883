import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Stack from '@mui/material/Stack';

export default function AAISPDFViewerTabs(props) {
    const classes = useStyles();
    const [tabsFragment, setTabsFragment] = useState();
    const [currentTab, setCurrentTab] = useState(0);

    function selectTab(fileObj){
        let i = 0;
        props.files.map((fileObjB) => {
            if(fileObj === fileObjB){
                setCurrentTab(i);
            }
            i++;
        });
        props.setNewFile(fileObj);
    }
  
    function init(){
      const files = props.files;
      const data = [];
      let i = 0;
      if(files.length > 0){
        props.files.map((fileObj) => {
            let thisData = 
                <div className={classes.centerContent} onClick={() => selectTab(fileObj)} color="primary">
                    {`Document ${(i+ 1)}`}
                </div>;
            if(i === currentTab){
                thisData = 
                    <div className={classes.selectedCenterContent} onClick={() => selectTab(fileObj)} color="primary">
                        {`Document ${(i+ 1)}`}
                    </div>;
            }
            //{fileObj.replace('.pdf', '')}

            data.push(thisData);
            i++;
          });
      }
      setTabsFragment(data);
    }
  
    useEffect(() => {
      init();
    }, [props.files, currentTab]);    
      

  return (
    <div className={classes.tabsContainer} key={0}>
    <Stack direction="row" spacing={0} className={classes.tabsContent}>{tabsFragment}</Stack>
    </div>
    
  );

}