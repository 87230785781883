/**
 * @description UserApi Module. This will have all the API's needed
 * to interact with the backend API's for the User
 * @module apis/UserApi
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @public
 */

import { API, Auth } from "aws-amplify";
import { sortUsers } from "../util/userUtil";
import * as commonUtil from "../util/commonUtil";

/**
 * @description Allows the querying of a particular User.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {String} userId the unique identifier for the user
 * you are trying to get.
 *
 * @example
 * get("userId123"); //will return the specific user
 *
 * @returns {* | null} a particular user if a `userId` is passed in
 *
 */
export async function get(userId) {
  if (!userId || typeof userId != "string" || userId.trim().length === 0)
    return null;

  const path = `/users/${userId}`;

  try {
    const user = await API.get("USER", path);
    storeUserInCache(user);
    return user;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Helper method to get access to the current logged in user
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @returns {object} a user object
 */
export async function getMe(fromCache = false) {
  const info = await Auth.currentAuthenticatedUser();
  if(!info?.signInUserSession?.idToken?.payload?.sub) return null;

  const userId = info.signInUserSession.idToken.payload.sub;

  if (fromCache) {
    const cachedResults = getFromCache(userId);
    if(cachedResults === null){
      return await get(userId);
    }
    return cachedResults;
  }

  const me = await get(userId);
  return me;
}

/**
 * @description Allows the querying of a multiple Users.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {Array} userIds an array of userIds for the users
 *
 * @example
 * useUsers(["userId1", "userId2"]); //will return the specific user
 *
 * @returns {Array} an array of users
 *
 *
 */
export async function getUsers(userIds) {
  if (!Array.isArray(userIds)) return [];

  //convert array to comman separated list
  const userIdsString = commonUtil.filterAndJoinResults(userIds);

  if (!userIdsString) return [];

  const path = `/users?userIds=${userIdsString}`;

  try {
    const users = await API.get("USER", path);
    storeUsersInCache(users);
    return sortUsers(users);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Allows the querying all Users. Be very careful.
 * This will be very slow as it has to look up all users
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * getAll(); //will return a array of all users
 *
 * @returns {Array} an array of all users
 *
 */
export async function getAll() {
  const path = "/users";

  try {
    const users = await API.get("USER", path);

    if (users && users.length > 0) {
      //do some storing in the cache
      storeUsersInCache(users);
      const userIds = users.map((user) => user.userId);
      const userIdsString = commonUtil.filterAndJoinResults(userIds);
      localStorage.setItem("getAllUsersUserIds", userIdsString);
    }

    return sortUsers(users);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Allows the querying all Users from the cache.
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * getAllFromCache(); //will return a array of all users
 *
 * @returns {Array} an array of all users
 *
 */
export function getAllFromCache() {
  const userIdsString = localStorage.getItem("getAllUsersUserIds");

  if (!userIdsString) return [];

  const userIds = userIdsString.split(",");
  const users = getUsersFromCache(userIds);

  return users;
}

/**
 * @description Resends the invitation email
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * resendInvite("findlay@gmail.com");
 *
 * @returns {Object}
 *
 */
export async function resendInvite(email, username) {
  const path = "/users";

  const params = {
    body: {
      email,
      username
    },
  };

  try {
    await API.patch("USER", path, params);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Updated a users name information
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @param {string} userId the unique id of the user you are updating
 * @param {string} firstName the new first name
 * @param {string} lastName the new last name
 * @returns {boolean} true if the update was successful
 */
export async function updateName(userId, firstName, lastName, secondaryEmail) {
  if (!userId || !firstName || !lastName) return false;

  if (!firstName || !lastName) return false;

  const path = `/users/${userId}`;
  const params = {
    body: {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      secondaryEmail: secondaryEmail && secondaryEmail.trim(),
    },
  };

  try {
    await API.put("USER", path, params);
  } catch (error) {
    alert(error.response.data.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function disableUser(userId) {
  if (!userId) return false;

  const path = `/users/${userId}/status`;

  try {
    const user = await API.del("USER", path);
    storeUserInCache(user);
  } catch (error) {
    alert(error);
    return false;
  }

  return true;
}

export async function enableUser(userId) {
  if (!userId) return false;

  const path = `/users/${userId}/status`;

  try {
    const user = await API.put("USER", path);
    storeUserInCache(user);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

/**
 * @description updates a user app-wide role. this is only for AAIS employees
 */
export async function updateAppRole(userId, role) {
  if (!userId || !role || !role.key) return false;

  const path = `/users/${userId}/apps/mss`;
  const body = { roleKey: role.key };

  const init = {
    body: body,
  };

  try {
    await API.put("USER", path, init);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function updateEmail(userId, emailType, email) {
  if (!userId || !emailType || !email.key || !email) return false;

  //TODO validate email

  const path = `/users/${userId}/emails/${email.key}`;
  const params = {
    body: {
      email: email.trim(),
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

/**
 *
 * @param {string} userId The userId whose phone number to update
 * @param {} phoneType
 * @param {String} number
 * @param {String} countryCode
 * @param {String} extension
 */
export async function addPhoneNumber(
  userId,
  phoneType,
  number,
  countryCode = "+1",
  extension
) {
  if (!userId || !phoneType || !phoneType.key || !number) return false;

  //TODO validate phone number

  const path = `/users/${userId}/phone-numbers/${phoneType.key}`;
  const params = {
    body: {
      phoneNumber: number.trim(),
      countryCode: countryCode.trim(),
      extension: extension,
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function updatePhoneNumber(
  userId,
  phoneType,
  phoneNumberId,
  number,
  countryCode = "+1",
  extension
) {
  if (!userId || !phoneType || !phoneType.key || !number || !phoneNumberId)
    return false;

  //TODO validate phone number

  const path = `/users/${userId}/phone-numbers/${phoneType.key}/ids/${phoneNumberId}`;
  const params = {
    body: {
      phoneNumber: number.trim(),
      countryCode: countryCode.trim(),
      extension: extension.trim(),
    },
  };

  try {
    await API.put("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function deletePhoneNumber(userId, phoneType, phoneNumberId) {
  if (!userId || !phoneType || !phoneType.key || !phoneNumberId) return false;

  const path = `/users/${userId}/phone-numbers/${phoneType.key}/ids/${phoneNumberId}`;

  try {
    await API.del("USER", path);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function changeProfilePicture(
  userId,
  contentType,
  fileExtension,
  imageInBase64
) {
  if (!userId || !contentType || !fileExtension || !imageInBase64) return false;

  //TODO validate file extension and content type

  const path = `/users/${userId}/profile-pictures`;
  const params = {
    body: {
      contentType: contentType.trim(),
      fileExtension: fileExtension.trim(),
      imageInBase64: imageInBase64,
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function deleteProfilePicture(
  userId,
  contentType,
  fileExtension,
  imageInBase64
) {
  if (!userId || !contentType || !fileExtension || !imageInBase64) return false;

  const path = `/users/${userId}/profile-pictures`;

  try {
    await API.del("USER", path);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

/**
 * @description gets the bulletins subscriptions for a specific User.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {String} userId the unique identifier for the user
 * you are trying to get.
 *
 * @example
 * getBulletins("userId123"); //will return the specific user bulletins
 *
 * @returns {*} a particular user if a `userId` is passed in
 *
 */
export async function getBulletins(userId) {
  if (!userId) return null;

  const path = `/users/${userId}/bulletins`;

  try {
    const bulletins = await API.get("USER", path);
    return bulletins;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description sets the bulletins subscriptions for a specific User.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {string} userId the unique identifier for the user
 * you are trying to get.
 * @param {boolean} enabled tell if you want it enabled
 * @param {LINE} line the line to update
 * @param {state} [state] the state to update. if this param is not set
 * then all states will be set
 *
 * @example
 * setBulletins("userId123", true, LINE.COP); //will set all states to true
 *
 * @returns {*} bulletins
 *
 */
export async function setBulletins(userId, enabled, line, state = null) {
  if (!userId || !line || !line.key) return null;

  let path = `/users/${userId}/bulletins/${line.key}`;

  if (state && !state.key) return null;
  //if you pass invalid state
  else if (state && state.key) path += `/${state.key}`; //valid state

  const params = {
    body: { enabled },
  };

  try {
    const bulletins = await API.put("USER", path, params);
    return bulletins;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function getNotifications(includeRead = false) {
  const userId = (await Auth.currentSession()).idToken.payload.sub;

  const path = `/users/${userId}/notifications?read=${includeRead}`;

  try {
    const notifications = await API.get("USER", path);
    return notifications;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function markNotificationAsRead(notificationId, isRead = true) {
  if (!notificationId) return null;

  const params = {
    body: { markAsRead: isRead },
  };

  const userId = (await Auth.currentSession()).idToken.payload.sub;
  const path = `/users/${userId}/notifications/${notificationId}`;
  try {
    await API.put("USER", path, params);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function getNotificationPreferences(
  type = "BULLETINS",
  line = "*",
  state = "*"
) {
  const userId = (await Auth.currentSession()).idToken.payload.sub;

  const path = `/users/${userId}/notification-preferences?type=${type}&line=${line}&state=${state}`;

  try {
    const notificationPreferences = await API.get("USER", path);
    return notificationPreferences;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function addNotificationPreference(type, line, state) {
  const userId = (await Auth.currentSession()).idToken.payload.sub;

  const path = `/users/${userId}/notification-preferences`;

  const params = {
    body: { type, line, state },
  };

  try {
    const notificationPreferences = await API.post("USER", path, params);
    return notificationPreferences;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function removeNotificationPreference(type, line, state) {
  const userId = (await Auth.currentSession()).idToken.payload.sub;

  const path = `/users/${userId}/notification-preferences`;

  const params = {
    body: { type, line, state },
  };

  try {
    const notificationPreferences = await API.del("USER", path, params);
    return notificationPreferences;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function acceptTerms(userId) {
  if (!userId) return;

  const path = `/users/${userId}/terms`;
  try {
    const user = await API.post("USER", path);
    storeUserInCache(user);
    return user;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export async function acceptAITerms(userId) {
  if (!userId) return;

  const path = `/users/${userId}/ai-terms`;
  try {
    const user = await API.post("USER", path);
    storeUserInCache(user);
    return user;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export function getFromCache(userId) {
  if (!userId) return;

  const userJSON = localStorage.getItem(userId);

  try {
    return JSON.parse(userJSON);
  } catch (error) {
    console.error(`could not parse user object ${userJSON}`, error);
  }

  return null;
}

export function getUsersFromCache(userIds) {
  if (!userIds || !Array.isArray(userIds)) return [];

  const users = [];
  userIds.forEach((userId) => {
    const user = getFromCache(userId);
    if (user) users.push(user);
  });
  return sortUsers(users);
}

export async function getUsersWithRecentNotifications() {
  const path = `/recent-notifications`;

  try {
    const users = await API.get("USER", path);
    return users;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/*
###############################################################################
                                PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
function storeUserInCache(user) {
  if (!user || !user.userId) return;
  localStorage.setItem(user.userId, JSON.stringify(user));
}

function storeUsersInCache(users) {
  if (!users || !Array.isArray(users)) return;

  users.forEach((user) => storeUserInCache(user));
}

/*
###############################################################################
                                END PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
