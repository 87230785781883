// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  /*cardHeader: {
    backgroundColor: "#5C666F",
    textAlign: "center",
    color: "white"
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
    textAlign: "left"
  },*/
  text: {
    fontSize: 12
  },
  /*card: {
    height: "160px",
    maxWidth: "600px"
  },*/
  moreVertIcon: {
    color: "white !important",
    transform: "translateY(-25%)",
    '&:hover': {
      textDecoration: "none",
      cursor: "pointer",
      color: "#0097AC !important"
    }
  },
  menuItem: {
    '&:hover': {
      textDecoration: "none",
      cursor: "pointer",
      color: "#0097AC !important"
    }
  },
  
  item: {
    maxWidth: "100%",
    minWidth: "100%",
    marginTop: 7,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 0,
    flexGrow: 1,
  },
  resultsContainer: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
loadingContainer: {
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(32),
},
title: {
  cursor: "pointer",
  paddingBottom: 2, 
},
titleNoLink: {
  paddingBottom: 2,
  color: "#000000",
},
card: {
  flexGrow: 1,
  orientation: "horizontal",
},
smallCard: {
  marginTop: "-40px",
  height: "114%"
},
smallerCard: {
  marginTop: "-40px",
  height: "134%"
},
longCard: {
  marginTop: "6px",
  height: 469
},
cardHeader: {
  backgroundColor: "#5C666F",
  textAlign: "center",
  fontSize: 14,
  color: "white",
  minHeight: 50,
  height: 50,
  maxHeight: 200,
  paddingBottom: 5,
},
cardContent: {
  paddingTop: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: 250,
  maxHeight: 280,
  overflowY: "auto",
  textAlign: "left",
  fontSize: 12
},
mainCardContent: {
  paddingTop: 0,
  backgroundColor: "white",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: 370,
  maxHeight: 370,
  overflowY: "auto",
  textAlign: "left",
  fontSize: 12
},
mainCardContentSmall: {
  paddingTop: 0,
  backgroundColor: "white",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: 369,
  maxHeight: 369,
  overflowY: "auto",
  textAlign: "left",
  fontSize: 12
},
longCardContent: {
  paddingTop: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: 485,
  maxHeight: 435,
  overflowY: "auto",
  textAlign: "left",
  fontSize: 12,
  borderBottomWidth: "0px",
},
singleCardContent: {
  paddingTop: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: "90%",
  maxHeight: "99%",
  overflowY: "auto",
  textAlign: "left",
  fontSize: "12px"
},
cardContentText: {
  paddingTop: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  //maxHeight: 280,
  overflowY: "auto",
  textAlign: "left",
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: "12px",
},
chipsPaper: {
  display: "flex",
  justifyContent: "left",
  flexWrap: "wrap",
  width: "100%",
  paddingLeft: "5px",
  paddingRight: "5px",
  paddingBottom: "5px",
  paddingTop: "5px",
},
setLeft: {
  marginLeft: -20,
  marginTop: -20,
},
noResults: {
  fontSize: 16,
  padding: "50px",
  textAlign: "center",
},
image:{
  fontSize: 23,
  padding: "10px",
  textAlign: "center",
},
chip: {
  margin: theme.spacing(0.25),
  minWidth: "45px",
},
chipSelected: {
  backgroundColor: "#5c666f",
  color: "white",
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
  //filter: "grayscale(80%)",
},
chipSelectedEdit: {
  backgroundColor: "#3878DB",
  color: "white",
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
  //filter: "grayscale(80%)",
},
chipProductSelected: {
  backgroundColor: "#5c666f",
  color: "white",
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
  //backgroundColor: "#204286"
},
chipNotFound: {
  color: "#949494",
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
},
chipNotFoundSlash: {
  color: "#949494",
  background: `linear-gradient(to left bottom, transparent 47.75%, rgba(74,82,92,1) 49.5%,  rgba(74,82,92,1), 51.5%, transparent 52.25%)`,
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
},
chipNotFoundEdit: {
  color: "#949494",
  margin: "2px",
  padding: "4px",
  minWidth: "45px"
},
cardActions: {
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
},
highlight: {
  fontSize: 13,
  color: theme.palette.grey[600],
  height: 100,
  flexGrow: 1,
  overflowY: "hidden",
  //border: "1px solid green",
},
smallGrid: {
  paddingTop: "0px",
  height: "73%"//"-webkit-fill-available"
},
smallerGrid: {
  paddingTop: "0px",
  height: "62%"//"-webkit-fill-available"
},
grid: {
  height: "100%"
},
longGrid:{
  height: "100%"
}
}));
