// Packages
import React, { useState } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";


// Styles
import { useStyles } from "./styles";

export default function AITermsContent(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Terms of Use</Typography>
        <Typography>Please review and agree to continue using AAISdirect.</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="h4">Artificial Intelligence Usage Reminder</Typography>
          <br />
          <Typography>
          No person or entity is permitted to upload, input, integrate, enter, or otherwise incorporate AAISdirect content, including AAIS-branded, proprietary information, or services, into any artificial intelligent application, tool, or solution or all the aforementioned activities by any other party. Questions about the acceptable use of AAISdirect content, can be directed to AAIS Member Engagement at membership@AAISonline.com.
          </Typography>
          <br />
        </DialogContentText>
        
      </DialogContent>

      <DialogActions>
        <Link
          className={classes.disagree}
          id={"ai-terms-disagree"}
          name={"ai-terms-disagree"}
          href="https://www.aaisonline.com"
          color="secondary"
        >
          DISAGREE
        </Link>
        <Button
          id={"ai-terms-agree"}
          name={"ai-terms-agree"}
          variant="contained"
          onClick={props.handleClose}
          color="primary"
          data-test="Agree"
        >
          AGREE
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
